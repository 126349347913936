import React from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { Helmet } from 'react-helmet';
import { withPrefix, Link } from 'gatsby';
import * as routes from '../shared/routes';
import Header from './Header';
import Footer from './Footer';
import useSiteMetadata from './SiteMetadata';
import '../skin/scss/main.scss';
import LatoRegular from '../skin/fonts/lato-regular.woff2';
import LatoBold from '../skin/fonts/lato-bold.woff2';
import LatoItalic from '../skin/fonts/lato-italic.woff2';
import LatoLight from '../skin/fonts/lato-light.woff2';

const Layout = ({ children, className, user, handleDiscount }) => {
  const { t: token } = typeof window !== `undefined` && queryString.parse(window.location.search);
  const { title, description, ogTitle, ogDescription } = useSiteMetadata();

  return (
    <div className={className}>
      <Helmet>
        <html lang="es" />
        <title>{title}</title>
        <meta charset="utf-8" />
        <meta name="description" content={description} />
        <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
        <link rel="canonical" href="https://www.quebueno.es/" />
        <link rel="icon" type="image/png" href={`${withPrefix('/')}favicon.png`} />
        <meta name="theme-color" content="#0065b8" />
        <meta property="og:locale" content="es_ES" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={ogTitle} />
        <meta property="og:description" content={ogDescription} />
        <meta property="og:url" content="https://www.quebueno.es/" />
        <meta property="og:site_name" content="QueBueno" />

        <link
          rel="preload"
          as="font"
          href={LatoRegular}
          type="font/woff2"
          crossOrigin="anonymous"
        />
        <link rel="preload" as="font" href={LatoBold} type="font/woff2" crossOrigin="anonymous" />
        <link rel="preload" as="font" href={LatoItalic} type="font/woff2" crossOrigin="anonymous" />
        <link rel="preload" as="font" href={LatoLight} type="font/woff2" crossOrigin="anonymous" />
      </Helmet>
      <Header
        token={token}
        routes={routes}
        userName={user && user.compound_name}
        handleDiscount={handleDiscount}
      />
      <div>{children}</div>
      <Footer routes={routes} Link={Link} />
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.element,
  className: PropTypes.string,
  user: PropTypes.object,
  handleDiscount: PropTypes.func,
};

export default Layout;
