import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Switch from '@material-ui/core/Switch';
import { ThemeProvider } from '@material-ui/core/styles';
import { theme } from '../../skin/scss/theme';
import './CookiesConfiguration.scss';
import { useState } from 'react';
import { COOKIES_POLICY } from '../../shared/routes';
import { Link } from 'gatsby';

const CookiesConfiguration = ({ visible, handleClose, initialValues }) => {
  const [cookies, setCookies] = useState(initialValues);

  const handleChange = (event) => {
    const cookies_v = [...cookies];
    cookies_v.find((it) => it.eventName === event.target.name).checked = event.target.checked;
    setCookies(cookies_v);
  };

  const handleSave = () => {
    handleClose(cookies);
  };

  const handleAccept = () => {
    const cookies_updated = cookies.map((cookie) => ({ ...cookie, checked: true }));
    setCookies(cookies_updated);
    handleClose(cookies_updated);
  };

  return (
    <Dialog open={visible} keepMounted className="QBDialog">
      <DialogTitle>
        <strong>Configuración de cookies</strong>
      </DialogTitle>
      <DialogContent>
        <div className="CookiesConfiguration-text">
          En ¡Québueno! utilizamos cookies para diferentes propósitos. Puedes elegir cuáles activar
          a continuación. Lee nuestra{' '}
          <Link className="Link" title="Cookies policies" to={COOKIES_POLICY}>
            Política de Cookies
          </Link>{' '}
          para informarte sobre cómo las usamos y cómo ajustar tus preferencias si cambias de
          opinión.
        </div>

        <br />
        <div className="CookiesConfiguration-cookies-container">
          {cookies &&
            cookies.map((cookie) => (
              <div key={cookie.name} className="CookiesConfiguration-cookiegroup">
                <div className="CookiesConfiguration-cookie">
                  <p>{cookie.name}</p>
                  <ThemeProvider theme={theme}>
                    <Switch
                      disabled={!cookie.editable}
                      color="primary"
                      name={cookie.eventName}
                      onChange={handleChange}
                      checked={cookie.checked}
                    />
                  </ThemeProvider>
                </div>
                <p>{cookie.subject}</p>
              </div>
            ))}
        </div>
      </DialogContent>
      <DialogActions className="CookiesConfiguration-actions">
        <div className="CookiesConfiguration-cookies-actions">
          <a href="#" className="Button Button--secondary" aria-label="Accept" onClick={handleSave}>
            <span>Guardar</span>
          </a>
          <a href="#" className="Button Button--primary" aria-label="Accept" onClick={handleAccept}>
            <span>Aceptar</span>
          </a>
        </div>
      </DialogActions>
    </Dialog>
  );
};

CookiesConfiguration.defaultProps = {
  visible: false,
};

CookiesConfiguration.propTypes = {
  visible: PropTypes.bool,
  handleClose: PropTypes.func,
  initialValues: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      eventName: PropTypes.string,
      checked: PropTypes.bool,
    })
  ),
};

export default CookiesConfiguration;
