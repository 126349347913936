import React, { useState, useEffect } from 'react';
import * as gtm from '../../shared/libs/gtm';
import { getCookie, setCookie } from '../../shared/libs/cookies';
import CookiesConfiguration from '../CookiesConfiguration';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import './CookiesDisclaimer.scss';
import initialValues from '../../shared/constants/cookies';

const CookiesDisclaimer = () => {
  const [visible, setVisible] = useState(false);
  const [configVisible, setConfigVisible] = useState(false);

  useEffect(() => {
    const visible = !getCookie('gatsby-gdpr-google-tagmanager');
    setVisible(visible);
    if (visible) {
      gtm.pushEvent('js', new Date());
      gtm.pushEvent('config', process.env.GATSBY_APP_GA);
      initialValues.forEach((cookie) => {
        if (cookie.tagEvent) {
          const events = cookie.events.reduce((acc, evt) => ({ ...acc, [evt]: 'denied' }), {});
          gtm.pushEvent(cookie.tagEvent, 'default', events);
        }
      });
    }
  }, []);

  const updateCookies = (cookies) => {
    cookies.forEach((cookie) => {
      cookie.events.forEach((evt) => setCookie(evt, cookie.checked));
    });
  };

  const handleConfiguration = () => {
    setVisible(false);
    setConfigVisible(true);
  };

  const handleReject = () => {
    setVisible(false);
    setConfigVisible(false);
    updateCookies(initialValues);
  };

  const handleClose = (cookies = initialValues) => {
    setVisible(false);
    setConfigVisible(false);
    setCookie('gatsby-gdpr-google-tagmanager', true);
    updateCookies(cookies);

    cookies.forEach((cookie) => {
      if (cookie.tagEvent) {
        const events = cookie.events.reduce(
          (acc, evt) => ({ ...acc, [evt]: cookie.checked ? 'granted' : 'denied' }),
          {}
        );
        gtm.pushEvent(cookie.tagEvent, 'update', events);
      } else if (cookie.checked) {
        cookie.events.forEach((evt) => gtm.pushEvent({ event: evt }));
      }
    });
  };

  return (
    <>
      <CookiesConfiguration
        visible={configVisible}
        initialValues={initialValues}
        handleClose={handleClose}
      />
      <Dialog open={visible} keepMounted className="QBDialog">
        <DialogTitle>
          <strong>
            Pero antes, hablemos de cookies{' '}
            <span role="img" aria-label="cookie">
              🍪
            </span>
          </strong>
        </DialogTitle>
        <DialogContent>
          <div className="CookiesDisclaimer-text">
            <p>
              En ¡Québueno! queremos ofrecerte la mejor experiencia. Usamos cookies propias y de
              terceros para analizar el tráfico, personalizar contenido y mejorar nuestros
              servicios.{' '}
              <span onClick={handleConfiguration} className="CookiesDisclaimer-link">
                Gestión y más información
              </span>
            </p>
          </div>
        </DialogContent>
        <DialogActions>
          <div className="CookiesDisclaimer-actions">
            <a
              href="#"
              className="Button Button--primary"
              aria-label="Accept"
              name="test_cookies_config"
              onClick={() =>
                handleClose(initialValues.map((cookie) => ({ ...cookie, checked: true })))
              }
            >
              <span>Aceptar todo</span>
            </a>
            <a
              href="#"
              className="Button Button--secondary"
              aria-label="Reject"
              name="test_cookies_accept"
              onClick={handleReject}
            >
              <span>Rechazar cookies no esenciales</span>
            </a>
          </div>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CookiesDisclaimer;
