import { useState, useEffect } from 'react';
import { getCookie, setCookie } from '../libs/cookies';
import { capitalize } from 'lodash';
import * as apiService from '../api.service';

const cookieName = 'ref_link';

export const useReferral = () => {
  const [isReferral, setIsReferral] = useState(false);
  const [isExpired, setIsExpired] = useState(false);
  const [referralLink, setReferralLink] = useState('');
  const [referrer, setReferrer] = useState(null);
  const [discount, setDiscount] = useState({});

  const isReturningUser = () => {
    return getCookie('returningUser') === 'true';
  };

  const checkReferralLinkParam = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const refLink = urlParams.get('ref_link');

    if (refLink) {
      if (!isReturningUser()) {
        handleValidateReferralLink(refLink);
      }
      const newUrl =
        window.location.protocol + '//' + window.location.host + window.location.pathname;
      window.history.replaceState({}, '', newUrl);
    }
  };

  const checkReferralLinkCookie = () => {
    const cookieValue = getCookie(cookieName);
    if (cookieValue && !isReturningUser()) {
      setData(JSON.parse(cookieValue));
    }
  };

  const handleValidateReferralLink = (link) => {
    const cookieValue = {
      isReferral: true,
      referralLink: link,
    };

    apiService
      .validateReferralLink(link)
      .then(({ status, data }) => {
        if (status !== 200) {
          cookieValue.isReferral = false;
          return;
        }

        cookieValue.isExpired = data.valid === false;
        cookieValue.referrer = capitalize(data?.referrer ?? 'Tu amigo');
        if (data.valid) {
          cookieValue.discount = { amount: data?.amount, type: data?.type };
        }
      })
      .finally(() => {
        setCookie(cookieName, JSON.stringify(cookieValue), { expires: 86400 * 7 });
        const cookie = getCookie('gatsby-gdpr-google-tagmanager');
        if (Boolean(cookie)) checkReferralLinkCookie();
      });
  };

  const setData = (cookieValue) => {
    setIsReferral(cookieValue.isReferral);
    setReferralLink(cookieValue.referralLink);
    if (cookieValue.isReferral) {
      setIsExpired(cookieValue.isExpired);
      setReferrer(cookieValue.referrer);
      cookieValue.discount && setDiscount(cookieValue.discount);
    }
  };

  useEffect(() => {
    checkReferralLinkParam();
    checkReferralLinkCookie();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      const cookie = getCookie('gatsby-gdpr-google-tagmanager');
      if (Boolean(cookie)) {
        clearInterval(interval);
        checkReferralLinkCookie();
      }
    }, 500);
  }, []);

  return { isReferral, isExpired, discount, referralLink, referrer };
};
