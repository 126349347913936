import config from '../config';

const DEBUG_GTM = config.nodeEnv === 'development' || config.nodeEnv === 'staging';
const debug = (msg, obj = {}) =>
  DEBUG_GTM && console.log(`%c ${msg}`, 'background: #117575; color: #fff', obj);

export function pushEvent() {
  if (window.dataLayer) {
    debug('[GTM] Pushing Event to DataLayer', arguments);
    window.dataLayer.push(arguments);
  }
}
