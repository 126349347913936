export default [
  {
    name: 'Cookies esenciales',
    eventName: 'cookies_esenciales',
    subject: 'Necesarias para el funcionamiento de la web',
    checked: true,
    editable: false,
    tagEvent: 'consent',
    events: [
      'ad_storage',
      'analytics_storage',
      'ad_user_data',
      'ad_personalization',
      'personalization_storage',
      'functionality_storage',
      'security_storage',
    ],
  },
  {
    name: 'Cookies de análisis',
    eventName: 'cookies_analisis',
    subject: 'Nos ayudan a mejorar con datos anónimos',
    checked: false,
    editable: true,
    events: ['facebook_consent'],
  },
  {
    name: 'Cookies de personalización',
    eventName: 'cookies_personalizacion',
    subject: 'Para adaptarnos a tus preferencias',
    checked: false,
    editable: true,
    events: [],
  },
  {
    name: 'Cookies publicitarias',
    eventName: 'cookies_publicitarias',
    subject: 'Para mostrar anuncios relevantes',
    checked: false,
    editable: true,
    events: ['adwords_consent'],
  },
];
